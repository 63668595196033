import { editableInputTypes } from '@testing-library/user-event/dist/utils';
import React, { useEffect, useState } from 'react'
import Moment from 'react-moment';
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom';
import AuthService from '../../api/services/HomeService';
import { alertErrorMessage, alertSuccessMessage } from '../../Components/CustomAlertMessage';
import LoaderHelper from '../../Components/Loading/LoaderHelper';
import Header from '../Header'
import Sidebar from '../Sidebar'

const ContestList = () => {

    const [contestList, setcontestList] = useState([]);
    const [contestList2, setcontestList2] = useState();

    const [winningType, setwinningType] = useState('');
    const [winAmount, setwinAmount] = useState('');
    const [catogName, setcatogName] = useState('');
    const [adminCommision, setadminCommision] = useState('');
    const [contestSize, setcontestSize] = useState('');
    const [contestType, setcontestType] = useState('');
    const [entryFee, setEntryFee] = useState('');
    const [teams, setTeams] = useState('');
    const [bonusPercentage, setbonusPercentage] = useState('');
    const [userId, setuserId] = useState();

    useEffect(() => {
        handleContestList()
    }, []);

    console.log('winningType', winningType);
    console.log('winAmount', winAmount);
    console.log('catogName', catogName);
    console.log('adminCommision', adminCommision);
    console.log('contestSize', contestSize);
    console.log('contestType', contestType);
    console.log('bonusPercentage', bonusPercentage);
    console.log('entryFee', entryFee);

    const handleContestList = async () => {
        LoaderHelper.loaderStatus(true);

        await AuthService.ContestList().then((result) => {
            if (result?.success) {
                LoaderHelper.loaderStatus(false);
                try {
                    setcontestList(result.data)
                    setcontestList2(result?.data.reverse())
                } catch {
                    alertErrorMessage(result.message);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result.message);
                console.log('Something went wrong');
            }
        })
    };

    const deleteContestList = async (id) => {
        await AuthService.deleteContest(id).then((result) => {
            if (result?.success) {
                try {
                    handleContestList()
                } catch {

                }
            } else {
                alertErrorMessage(result?.message)
            }
        })
    };

    const ContestListstatus = async (id, status) => {
        await AuthService.SetContestStatus(id, status).then((result) => {
            if (result?.success) {
                try {
                    handleContestList()
                } catch {
                }
            } else {

                console.log('Something went wrong');
            }
        })
    };

    const EditContest = async (userId, catogName, winAmount, contestSize, contestType, EntryFee, winningType, adminCommision, team, bonusPercentage) => {

        await AuthService.editContest(userId, catogName, winAmount, contestSize, contestType, EntryFee, winningType, adminCommision, team, bonusPercentage).then((result) => {
            if (result?.success) {
                try {
                    alertSuccessMessage('Contest Edited Successfully')
                    handleContestList()

                } catch {
                    alertErrorMessage();
                }
            } else {
                alertErrorMessage(result?.message);
            }
        })
    };


    const handleInput = (e) => {
        switch (e.target.name) {
            case 'contest_price_type':
                setwinningType(e.target.value)
                break;
            case 'winning_amount':
                setwinAmount(e.target.value)
                break;
            case 'category_id':
                setcatogName(e.target.value)
                break;
            case 'admin_comission':
                setadminCommision(e.target.value)
                break;
            case 'contest_size':
                setcontestSize(e.target.value)
                break;
            case 'contest_type':
                if (e.target.value === 'Free') {
                    setwinAmount('');
                    setadminCommision('');
                } else {
                    setcontestType(e.target.value);
                }
                break;
            case 'entery_fee':
                setEntryFee(e.target.value)
                break;
            case 'teams':
                setTeams(e.target.value)
                break;
            case 'usable_bonus_percentage':
                setbonusPercentage(e.target.value)
                break;

            default:
                break;
        }


    };



    var calcEntryFee;
    if (winAmount && adminCommision && contestSize) {
        calcEntryFee = Math?.ceil((+winAmount + (+winAmount / 100 * +adminCommision)) / +contestSize);

    } else {
        calcEntryFee = 0
    };


    const handleEditDetails = (data) => {
        setuserId(data?._id)
        setcatogName(data?.categoryName)
        setwinAmount(data?.WinningAmount)
        setcontestSize(data?.Contestsize)
        setcontestType(data?.ContestType)
        setwinningType(data?.EnteryType)
        setEntryFee(data?.EnteryFee)
        setadminCommision(data?.AdminCommission)
        setTeams(data?.teams)
        setbonusPercentage(data?.UsableBonusPercantage)
    }
    const handleSearch = (e) => {
        let filterd;

        filterd = contestList2.filter((data) => {
            if (e.target.value === 'All') {
                return data
            } else {
                return data?.categoryName === e.target.value
            }
        })
        setcontestList(filterd)
    }
    const handleFilter = (e) => {
        let filterd;
        filterd = contestList2.filter((data) => {
            if (e.target.value === 'All') {
                return data
            } else {
                return data?.status === e.target.value
            }
        })
        setcontestList(filterd)
    }

    let contestCategory = []
    let uniqueContestCategory;

    for (let i = 0; i < contestList2?.length; i++) {
        contestCategory.push(contestList2[i]?.categoryName)
    }
    uniqueContestCategory = [...new Set(contestCategory)];

    /* custom pagination */

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(5);


    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected + 1);
    };

    const pageCount = Math.ceil(contestList.length / itemsPerPage);
    const currentItems = contestList.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );
    // custom paginatio ends

    return (
        <>

            <div id="layoutSidenav_content">
                <main>
                    <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                        <div className="container-xl px-4">
                            <div className="page-header-content pt-4">
                                <div className="row align-items-center justify-content-between">
                                    <div className="col-auto mt-4">
                                        <h1 className="page-header-title">
                                            <div className="page-header-icon"><i className="ri-trophy-line"></i></div>
                                            Contest List
                                        </h1>
                                        {/* <!-- <div className="page-header-subtitle">Example dashboard overview and content summary</div> --> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>
                    {/* <!-- Main page content-->
        <!-- Example Colored Cards for Dashboard Demo--> */}
                    <div className="container-xl px-4 mt-n10">

                        <div className="card mb-4">

                            <div className="card-body">
                                <div className=" filter_bar mb-4 mb-md-5">
                                    <form method="get" acceptCharset="utf-8" validaterset="validaterset" className="form-inline search_form" action="/admin/users/referraldata">
                                        <div className="row gx-2 align-items-center">
                                            {/* <div className="form-group mb-0 col text-end">
                                    <div className="input text">
                                        <label className="me-3">Filter</label>
                                    </div>
                                </div> */}
                                            <div className="form-group mb-0 col-4">
                                                <select name="category_id" className="form-control form-select" onChange={(e) => { handleSearch(e) }}>
                                                    <option value="" selected disabled>Select Category</option>
                                                    {uniqueContestCategory ? uniqueContestCategory?.map((data, index) => {
                                                        return (
                                                            <>
                                                                <option value={data}>{data}</option>
                                                            </>
                                                        )
                                                    }) : ''}
                                                    <option value='All'>All</option>

                                                </select>
                                            </div>
                                            <div className="form-group mb-0 col-4">
                                                <select name="category_id" className="form-control form-select" onChange={(e) => { handleFilter(e) }}>
                                                    <option value="" selected disabled>Select Status</option>

                                                    <option value='Active'>Active</option>
                                                    <option value='Inactive'>InActive</option>
                                                    <option value='All'>All</option>
                                                </select>
                                            </div>
                                            {/* 
                                                <div className="form-group  mb-0 col">
                                                    <div className="input text">
                                                        <input type="date" name="start_date" className="form-control datepicker-input start_date" placeholder="Enter Registered From" id="start-date" />
                                                    </div>
                                                </div>
                                                <div className="form-group  mb-0 col">
                                                    <div className="input text">
                                                        <input type="date" name="end_date" className="form-control datepicker-input end_date" placeholder="To" id="end-date" />
                                                    </div>
                                                </div>
                                                <div className="form-group  mb-0 col">
                                                    <div className="row gx-2" >
                                                        <div className="col" >
                                                            <button type="button" className="btn btn-primary submit px-5 w-100 btn-block">Search</button>
                                                        </div>
                                                        <div className="col" >
                                                            <a href="#" className="btn btn-dark px-4  w-100 btn-block"><i className="fa fa-undo me-2"></i> Reset</a>
                                                        </div>
                                                    </div>
                                                </div> */}
                                        </div>
                                    </form>
                                </div>


                                <table id="datatablesSimple">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Added date</th>
                                            <th>Category name</th>
                                            <th>Winning amount INR</th>
                                            <th>Contest size</th>
                                            <th>Contest type</th>
                                            <th>Entry fee INR</th>
                                            <th>Entery Type</th>
                                            <th>Admin Commission</th>
                                            <th>Teams</th>
                                            <th>Status</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tfoot>
                                        <tr>
                                            <th>#</th>
                                            <th>Added date</th>
                                            <th>Category name</th>
                                            <th>Winning amount INR</th>
                                            <th>Contest size</th>
                                            <th>Contest type</th>
                                            <th>Entry fee INR</th>
                                            <th>Entery Type</th>
                                            <th>Admin Commission</th>
                                            <th>Teams</th>
                                            <th>Status</th>
                                            <th>Actions</th>
                                        </tr>
                                    </tfoot>
                                    <tbody>
                                        {currentItems ? currentItems?.map((data, index) => {
                                            let objIndex;
                                            objIndex = contestList.findIndex(check => check?._id == data._id);
                                            return (
                                                <tr>
                                                    <td>{objIndex + 1}</td>
                                                    <td><Moment date={data?.createdAt} format="YYYY/MM/DD HH:mm" /></td>
                                                    <td>{data?.categoryName}</td>
                                                    <td>{data?.WinningAmount}</td>
                                                    <td>{data?.Contestsize}</td>
                                                    <td>{data?.ContestType}</td>
                                                    <td>{data?.EnteryFee}</td>
                                                    <td>{data?.EnteryType}</td>
                                                    <td>{data?.AdminCommission}</td>
                                                    <td>{data?.teams}</td>
                                                    {data?.status === 'Active' ? <td><button type="btn" className="btn badge bg-success text-white rounded-pill" o onClick={() => { ContestListstatus(data?._id, 'Inactive') }}>{data?.status}</button></td> : <td><button type="btn" className="btn badge bg-danger text-white rounded-pill" onClick={() => { ContestListstatus(data?._id, 'Active') }}  >{data?.status}</button></td>}

                                                    <td>
                                                        <div className="table_action_group">
                                                            <button type="btn" className="btn badge bg-success text-white rounded-pill"
                                                                data-bs-toggle="modal" data-bs-target="#editContest"
                                                                onClick={() => { handleEditDetails(data) }}>Edit</button>

                                                            {/* <button type="btn" className="btn badge bg-danger text-white rounded-pill" onClick={() => { deleteContestList(data?._id) }}>Delete</button> */}
                                                            <a href="/ScheduleContest" className="btn badge bg-secondary text-white rounded-pill">Schedule Contest</a>

                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        }) : ''}

                                    </tbody>
                                </table>
                                <ReactPaginate
                                    pageCount={pageCount}
                                    pageRangeDisplayed={3}
                                    marginPagesDisplayed={2}
                                    onPageChange={handlePageChange}
                                    containerClassName={'customPagination'}
                                    activeClassName={'active'}
                                />
                            </div>
                        </div>
                    </div>




                </main>
            </div>


            {/* Edit Contest Modal */}

            <div className="modal" id="editContest" tabIndex="-1" aria-labelledby="editwallrtlmodalTitle" style={{ display: 'none' }} aria-hidden="true">
                <div className="modal-dialog  alert_modal modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="editwallrtlmodalTitle">Edit User Details</h5>
                            <button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                            <hr />
                        </div>
                        <div className="modal-body">

                            <form>

                                <div className="form-group  mb-3 position-relative ">
                                    <label for="category_id">Category name </label>
                                    <div className="input select">
                                        <select name="category_id" className="form-control form-select category" id="category_id"
                                            onChange={(e) => { handleInput(e) }} value={catogName} >
                                            {/* <option value="" selected disabled>Select Category</option> */}
                                            {uniqueContestCategory ? uniqueContestCategory?.map((data, index) => {
                                                return (
                                                    <>
                                                        <option value={data}>{data}</option>
                                                    </>
                                                )
                                            }) : ''}
                                        </select>
                                    </div>
                                </div>


                                <div className="form-group  mb-3 position-relative ">
                                    <label for="winning-amount">Winning amount (INR) <span className="required">*</span></label>
                                    <input type="number" name="winning_amount" className="form-control winning_amount" placeholder="Winning amount" id="winning-amount"
                                        value={winAmount} onChange={(e) => { handleInput(e) }} />
                                </div>

                                <div className="form-group  mb-3 position-relative ">
                                    <label for="contest_size">Contest Size <span className="required">*</span></label>
                                    <input type="number" name="contest_size" className="form-control contest_size" placeholder="Contest size" id="contest_size"
                                        value={contestSize} onChange={(e) => { handleInput(e) }} />
                                </div>

                                <div className="form-group  mb-3 position-relative ">
                                    <label for="contest_type">Contest Type <span className="required">*</span></label>
                                    <select name="contest_type" className="form-control form-select contest_type" id="contest_type"
                                        value={contestType} onChange={(e) => { handleInput(e) }} >
                                        <option value="Classic" selected="selected">Classic</option>
                                        <option value="Second Inning">Second Inning</option>
                                    </select>
                                </div>

                                <div className="form-group  mb-3 position-relative ">
                                    <label for="entery_fee">Entery Fee <span className="required">*</span></label>
                                    <input type="number" name="entery_fee" className="form-control entery_fee" placeholder="Entery Fee" id="entery_fee"
                                        value={entryFee} onChange={(e) => { handleInput(e) }} />
                                </div>

                                <div className="form-group  mb-3 position-relative ">
                                    <label for="contest-price-type">Entery Type <span className="required">*</span></label>
                                    <select name="contest_price_type" className="form-control form-select contest_price_type" id="contest-price-type"
                                        value={winningType} onChange={(e) => { handleInput(e) }} >
                                        <option value="Paid" selected="selected">Paid</option>
                                        <option value="Free">Free</option>
                                    </select>
                                </div>

                                <div className="form-group  mb-3 position-relative ">
                                    <label for="admin_commission">Admin Commission <span className="required">*</span></label>
                                    <input type="number" name="admin_comission" className="form-control admin_commission" placeholder="Entery Fee" id="admin_commission"
                                        value={adminCommision} onChange={(e) => { handleInput(e) }} />
                                </div>

                                <div className="form-group  mb-3 position-relative ">
                                    <label for="teams">Teams <span className="required">*</span></label>
                                    <input type="number" name="teams" className="form-control teams" placeholder="Entery Fee" id="teams"
                                        value={teams} onChange={(e) => { handleInput(e) }} />
                                </div>

                                <div className="form-group  mb-3 position-relative ">

                                    <label for="usable-bonus-percentage">Usable Bonus Percentage (0-100)</label>
                                    <input type="number" name="usable_bonus_percentage" className="form-control" placeholder="Usable Bonus Percentage"
                                        id="usable-bonus-percentage" onChange={(e) => { handleInput(e) }} value={bonusPercentage} />
                                </div>



                                <div className="form-group  position-relative mt-4">
                                    <button data-bs-dismiss="modal" className="btn btn-primary   btn-block w-100" type="button"
                                        onClick={() => {
                                            EditContest(userId, catogName, winAmount, contestSize, contestType, entryFee, winningType, adminCommision,
                                                teams, bonusPercentage)
                                        }}  >Submit Details</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default ContestList
