import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import DataTable from "react-data-table-component";
import LoaderHelper from "../../Components/Loading/LoaderHelper";
import AuthService from "../../api/services/HomeService";
import { alertErrorMessage } from "../../Components/CustomAlertMessage";
import { customStyles } from "../../Components/CustomStyle DataTable";



const ReferralList = () => {



    const [referralList, setReferralList] = useState([]);
    const [filterData, setFilterData] = useState([]);


    const handleReferralList = async () => {
        LoaderHelper.loaderStatus(true);
        try {
            const result = await AuthService.referralList();
            if (result?.success) {
                let filterData = result?.data.reverse().map((item, index) => ({ ...item, index: index + 1, id: index + 1 }));
                setFilterData(filterData);
                setReferralList(filterData);
            } else {
                alertErrorMessage(result?.message);
            };
        } catch (error) {
            alertErrorMessage(error?.message);
        }
        finally { LoaderHelper.loaderStatus(false) };
    };


// console.log('referralList', referralList);

    const columns = [
        { name: 'S.No', width: '80px', selector: (row, index) => row?.index, wrap: true, sort: true },
        { name: 'Sponsor Name', selector: row => row?.from?.username ? row?.from?.username : '---', wrap: true, sort: true },
        { name: <>Sponsor Mobile Number</>, selector: row => row?.from?.mobile_number, wrap: true, sort: true },
        { name: <>Refer Code</>, selector: row => <div className="text-dark fw-bolder fst-italic">{row?.from?.refercode}</div>, wrap: true, sort: true },
        { name: 'User Name', selector: row => row?.to?.username ? row?.to?.username : '---', wrap: true, sort: true },
        { name: <>User Mobile Number</>, selector: row => row?.to?.mobile_number ? row?.to?.mobile_number : '---', wrap: true, sort: true },
    ];

    function searchFilter(userInput, keys = ["from.username", "to.username", "from.mobile_number", "from.refercode", "to.mobile_number"]) {
        const searchTerm = userInput?.toLowerCase();
      
        const matchingObjects = filterData.filter((obj) => {
          return keys.some((key) => {
            const value = key.split(".").reduce((acc, curr) => acc?.[curr], obj);
            return value?.toString()?.toLowerCase()?.includes(searchTerm);
          });
        });
      
        setReferralList(matchingObjects);
      }


    useEffect(() => {
        handleReferralList()
    }, []);


    return (
        <div id="layoutSidenav_content">
            <main>
                <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                    <div className="container-xl px-4">
                        <div className="page-header-content pt-4">
                            <div className="row align-items-center justify-content-between">
                                <div className="d-flex justify-content-between mt-4">
                                    <h1 className="page-header-title">
                                        <div className="page-header-icon"><i className="far fa-user"></i></div>
                                        Referral List
                                    </h1>

                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <div className="container-xl px-4 mt-n10">
                    <div className="card mb-4">
                        <div className="card-header d-flex justify-content-between">
                            <div className="col-5">
                                <input className="form-control " id="inputLastName" type="text" placeholder="Search here..." name="search" onChange={(e)=>searchFilter(e.target.value)} />
                            </div>
                            {referralList.length === 0 ? "" :
                                <div className="dropdown">

                                    <CSVLink data={referralList} className="btn btn-dark btn-sm dropdown-toggle">Export CSV</CSVLink>

                                </div>
                            }
                        </div>
                        <div className="card-body mt-3">
                            {referralList.length === 0 ? <h6 className="ifnoData"><img alt="" src="assets/img/no-data.png" /> <br />No Data Available</h6> :
                                <div className="table-responsive d-flex flex-column" width="100%">
                                    <DataTable columns={columns} data={referralList} customStyles={customStyles} pagination />
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </main>
        </div>

    )
}

export default ReferralList;