import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import LoaderHelper from '../../Components/Loading/LoaderHelper'
import { alertErrorMessage, alertSuccessMessage } from '../../Components/CustomAlertMessage'
import AuthService from '../../api/services/HomeService'
import moment from 'moment'
import { $ } from 'react-jquery-plugin'
import Swal from 'sweetalert2'
import { customStyles } from '../../Components/CustomStyle DataTable'


const TDS = () => {
    const [tdsList, setTdsList] = useState([]);
    const [transDetails, setTransDetails] = useState([]);

    const getTdsList = async () => {
        LoaderHelper.loaderStatus(true);
        try {
            const result = await AuthService.TDSList();
            LoaderHelper.loaderStatus(false);
            if (result?.success) {
                const filtered = result?.data?.reverse().map((item, index) => ({ ...item, index: index + 1, id: index + 1 }))
                setTdsList(filtered);
            } else {
                alertErrorMessage(result?.message);
            };
        } catch (error) {
            alertErrorMessage(error?.message);
        }
        finally { LoaderHelper.loaderStatus(false); };
    }


    useEffect(() => {
        getTdsList();
    }, [])


    const columns = [
        { name: 'S.No.', width: '80px', selector: (row, index) => row?.index, wrap: true, sort: true },
        { name: 'Created At', selector: row => moment(row?.createdAt).format('DD/MM/YY LT'), wrap: true, sort: true },
        { name: 'User Id', selector: row => row?.userId?._id, wrap: true, sort: false },
        { name: <div>Phone Number</div>, selector: row => row?.userId?.mobile_number, wrap: true, sort: false },
        {
            name: 'TDS Filled', selector: row => <>{row?.tdsFilling === false ?
                <input type="checkbox" onChange={() => handleTDSFilled(row?._id)} />
                : <span className='text-success'>Filled</span>}</>, wrap: true, sort: false
        },
        { name: 'TDS Amount', selector: row => row?.tdsAmount.toFixed(2), wrap: true, sort: false },
        { name: <div>TDS Percentage</div>, selector: row => row?.tdsPercentage.toFixed(2), wrap: true, sort: false },
        { name: 'Amount', selector: row => row?.amount.toFixed(2), wrap: true, sort: false },
        { name: 'Total Amount', selector: row => row?.totalAmount.toFixed(2), wrap: true, sort: false },
        {
            name: 'Actions', selector: row => <>
                <button className='bg-primary rounded border p-2 px-3 text-white border-bg-primary' title='View'
                    onClick={() => {
                        $('#view_modal').modal('show');
                        setTransDetails(row);
                        console.log([row])
                    }}>
                    View</button></>
        }
    ];


    const handleTDSFilled = async (id) => {
        const popUp = await Swal.fire({
            title: 'Do you confirm?',
            icon: 'info',
            showDenyButton: true,
            confirmButtonText: 'Yes',
            denyButtonText: `No`,
        })
        if (popUp?.isConfirmed) {
            LoaderHelper.loaderStatus(true);
            try {
                const result = await AuthService.TDSFilled(id);
                LoaderHelper.loaderStatus(false);
                if (result?.success) {
                    alertSuccessMessage(result?.message);
                    getTdsList();
                } else {
                    alertErrorMessage(result?.message);
                }
            } catch (error) {
                alertErrorMessage(error?.message);
            }
            finally { LoaderHelper.loaderStatus(false); };
        } else if (popUp?.isDenied) {
            Swal.fire('Changes are not Saved');
        }
    };


    return (
        <>

            <div id="layoutSidenav_content">
                <main>
                    <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                        <div className="container-xl px-4">
                            <div className="page-header-content pt-4">
                                <div className="row align-items-center justify-content-between">
                                    <div className="col-auto mt-4">
                                        <h1 className="page-header-title">
                                            <div className="page-header-icon"><i className="ri-brackets-fill"></i></div>
                                            TDS List
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>
                    {/* <!-- Main page content-->
            <!-- Example Colored Cards for Dashboard Demo--> */}
                    <div className="container-xl px-4 mt-n10">
                        <div className="card mb-4">
                            <div className="card-body">
                                <DataTable columns={columns} data={tdsList} pagination customStyles={customStyles} />
                            </div>
                        </div>
                    </div>
                </main>
            </div>



            {/* View Transaction and Pan Details Modal */}

            <div className="modal" id="view_modal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog  alert_modal modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h2 className="modal-title" id="exampleModalCenterTitle">
                                User Details
                            </h2>

                            <button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <hr />
                        <div className="modal-body d-flex justify-content-between">
                            <div>
                                <h5 className='fw-bolder'>Transaction Details</h5>
                                <hr />
                                <p>Transaction Id: {transDetails?.transaction?.transaction_id}</p>
                                <p>TDS Amount: {transDetails?.transaction?.tdsamount}</p>
                                {transDetails?.transaction?.transaction_status === "failed" ? '' : <p>Transaction Status: {transDetails?.transaction?.transaction_status}</p>}
                                <p> Transaction Type: {transDetails?.transaction?.transaction_type}</p>

                            </div>
                            <div>
                                <h5 className='fw-bolder'>PAN Details</h5>
                                <hr />
                                <p> Name on PAN: {transDetails?.panDetails?.NameOnPan}</p>
                                <p> PAN Number: {transDetails?.panDetails?.pan_number}</p>
                                <p> PAN Status: {transDetails?.panDetails?.pan_status}</p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TDS
